
	import { Component, Vue, Watch } from "vue-property-decorator";
	import request from "@/request";
	import { AxiosPromise } from "axios";
	import CountUp from "@/components/countup/countup.vue";
	import Loading from "@/components/loading/Loading.vue";
	import { Page, Switch, Table, TableColumn } from "view-design";
	import { Route } from "vue-router";
	import Tour from "@/components/tour/tour.vue";

	const guild = require("@/assets/tourGuid.json");

	interface countDetail {
		name:string,
		total:number,
		warn?:number,
		color?:string,
		id:number
	}

	@Component({
		components: {
			CountUp,
			Loading,
			Table,
			Page,
			ISwitch: Switch,
			Tour
		}
	})
		export default class DeviceInfo extends Vue {
		warnCount:number = 0;
		repairCount:number = 0;
		maintenanceCount:number = 0;
		normalCount:number = 0;
		countActive:number = 0;
		systemType:countDetail[] = [];
		topLevelActive:number = 0;
		category:countDetail[] = [];
		categoryActive:number = 0;
		device:any[] = [];
		categoryLoading:boolean = true;
		systemTypeLoading:boolean = true;
		deviceLoading:boolean = true;
		page:number = 0;
		total:number = 0;
		steps = [
			{
				target: "[data-v-step=\"1\"]",
				content: "对应状态的设备总数，点击可以在列表中查看对应设备，再次点击返回所有设备。"
			},
			{
				target: "[data-v-step=\"2\"]",
				content: "当前楼层的系统列表，点击可以切换模型和设备及类型的数据。"
			},
			{
				target: "[data-v-step=\"3\"]",
				content: "当前楼层的设备类型列表，点击可以在列表中查看对应类型的设备，再次点击返回所有设备。"
			},
			{
				target: "[data-v-step=\"4\"]",
				content: "当前楼层的设备列表，点击可以在模型中定位。"
			}
		];
		readonly columns:TableColumn[] = [
			{
				title: "类型",
				key: "devicetypen",
				width: 80
			},
			{
				title: "编号",
				key: "code",
				width: 120
			},
			{
				title: "状态",
				key: "status",
				width: 80
			},
			{
				title: "供应商",
				key: "company",
				width: 80
			},
			{
				title: "位置",
				key: "place",
				width: 80,
				ellipsis: true,
				tooltip: true
			},
			{
				title: "规格",
				key: "specification",
				ellipsis: true,
				tooltip: true
			}
		];
		readonly flow:boolean = this.$store.state.flow;
		private MainLink:HTMLLinkElement = document.createElement("link");

		get height ():number {
			return document.body.clientHeight - 650;
		}

		get pageSize ():number {
			return Math.floor((this.height - 36) / 40);
		}

		getSummaryCount () {
			// eslint-disable-next-line camelcase
			const room__floor = this.$store.state.floorValue;
			request({
				url: "/device/device2/",
				params: {
					status__in: "100,101,110,111",
					pagesize: 1,
					room__floor
				}
			}).then(({ data }) => {
				this.repairCount = data.count;
			});
			request({
				url: "/device/device2/",
				params: {
					status__in: "010,011,110,111",
					pagesize: 1,
					room__floor
				}
			}).then(({ data }) => {
				this.maintenanceCount = data.count;
			});
			request({
				url: "/device/device2/",
				params: {
					status__in: "001,011,101,111",
					pagesize: 1,
					room__floor
				}
			}).then(({ data }) => {
				this.warnCount = data.count;
			});
			request({
				url: "/device/device2/",
				params: {
					status: "000",
					pagesize: 1,
					room__floor
				}
			}).then(({ data }) => {
				this.normalCount = data.count;
			});
		}

		getCategory (system:{ id:number, name:string }):void {
			const { id, name } = system;
			this.categoryLoading = true;
			this.deviceLoading = true;
			this.topLevelActive = id;
			this.categoryActive = 0;
			this.$store.commit("changeSystem", /全部/.test(name) ? "All" : name.replace(/系统/, ""));
			request({
				url: "/device/devicecategory/",
				params: {
					DeviceType__mepsystemtype: id || null,
					Device__room__floor: this.$store.state.floorValue
				}
			}).then(({ data }) => {
				this.category = data.map((t:any) => ({
					name: t.name,
					total: t.devices,
					id: t.id
				}));
				this.categoryLoading = false;
				this.pageChange(1);
			});
		}

		getDevice (id:number) {
			if (this.categoryActive === id) {
				this.categoryActive = 0;
			} else {
				this.categoryActive = id;
			}
			this.pageChange(1);
		}

		pageChange (page:number) {
			// eslint-disable-next-line camelcase
			const devicetype__mepsystemtype2 = this.topLevelActive || null;
			// eslint-disable-next-line camelcase
			const devicetype__device_category = this.categoryActive || null;
			this.deviceLoading = true;
			this.page = page;
			const status = this.countActive;
			request({
				url: "/device/device2/",
				params: {
					page,
					pagesize: this.pageSize,
					devicetype__mepsystemtype2,
					devicetype__device_category,
					room__floor: this.$store.state.floorValue,
					status__in: status === 0 ? null : status === 1 ? "001,011,101,111" : status === 2 ? "100,101,110,111" : status === 3 ? "010,011,110,111" : "000"
				}
			}).then(({ data }) => {
				this.device = data.results.map((t:any) => ({
					devicetypen: t.device_category_name,
					code: t.code,
					status: ((status:string):string => {
						if (status === "000") {
							return "正常";
						} else {
							let sArray:string[] = [];
							if (status.charAt(0) === "1") {
								sArray.push("维修");
							}
							if (status.charAt(1) === "1") {
								sArray.push("维保");
							}
							if (status.charAt(2) === "1") {
								sArray.push("预警");
							}
							return sArray.join();
						}
					})(t.status),
					company: t.company || "",
					place: ((place:string):string => place.split("/")[ 2 ])(t.spacestr),
					specification: t.devicetype.dformat,
					uid: t.guid
				}));
				this.deviceLoading = false;
				this.total = data.count;
				// @ts-ignore
				const history = JSON.parse(localStorage.getItem("tour"));
				if (!history.deviceMain) {
					this.$tours.deviceMain.start();
					localStorage.setItem("tour", JSON.stringify(Object.assign(guild, history, { deviceMain: true })));
				}
			});
		}

		rowClick (row:any) {
			this.$store.commit("selectDevice", row.uid);
		}

		changeFlow (flag:boolean) {
			this.$store.commit("changeFlow", flag);
		}

		changeCountActive (id:number) {
			this.countActive = id === this.countActive ? 0 : id;
			this.pageChange(1);
		}

		renderCss () {
			less.render(lessFiles.deviceMain, {
				javascriptEnabled: true,
				modifyVars: {
					"@baseColor": this.$store.state.baseColor,
					"path": "/static/UnityWeb/desktop/"
				},
				paths: [ "/static/UnityWeb/desktop/" ]
			}).then(data => {
				this.MainLink && this.MainLink.remove();
				const href:string = URL.createObjectURL(new Blob([ data.css ]));
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
				this.MainLink = link;
			});
		}

		mounted () {
			// this.renderCss();
			// this.$store.commit("changeFlow", this.flow);
			request({
				url: "/device/mepsystemtype/"
			}).then(({ data }) => {
				let p:AxiosPromise[] = [];
				let total:number = 0;
				data.forEach((t:any) => {
					const temp = request({
						url: "/device/device/",
						params: {
							pagesize: 1,
							devicetype__mepsystemtype2: t.id,
							room__floor: this.$store.state.floorValue
						}
					});
					p.push(temp);
					temp.then((resolve) => {
						total += t.total = resolve.data.count;
					});
				});
				Promise.all(p).then(() => {
					(data as any[]).splice(0, 0, { name: "全部系统", total, id: 0 });
					this.getCategory({ id: 0, name: "All" });
					this.systemType = data;
					this.systemTypeLoading = false;
				});
			});
			this.getSummaryCount();
		}

		@Watch("$store.state.floorValue")
		onFloorChange (value:number | "", old:number) {
			if (value === "") {
				this.$store.commit("changeModule", { type: "device", url: "", floor: old });
			}
		}

		@Watch("$store.state.baseColor")
		onColorChange () {
			this.renderCss();
		}

		beforeRouteLeave (to:Route, from:Route, next:Function) {
			this.$store.commit("changeFlow", false);
			this.MainLink.remove();
			next();
		}
	}
