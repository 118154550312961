
	import { Component, Prop, Vue } from "vue-property-decorator";

	interface step {
		target:string,
		content:string
	}

	@Component
	export default class Tour extends Vue {
		@Prop(String) readonly name!:string;
		@Prop(Array) readonly steps!:step[];
		readonly option = {
			labels: {
				buttonSkip: "跳过指引",
				buttonPrevious: "上一步",
				buttonNext: "下一步",
				buttonStop: "完成"
			}
		}
	}
